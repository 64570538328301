import { createSlice } from '@reduxjs/toolkit';
import { reducerUtils } from '../reducer.util';
import { createSagaReducer } from '../saga.util';
import { getOrderList } from './orderApi';

const name = 'order';

const initialState = {
    getOrderCount: reducerUtils.initial(),
    getOrderList: reducerUtils.initial(),
}

const slice = createSlice({
    name,
    initialState,
    reducers: {
        ...createSagaReducer('getOrderCount', 'getOrderCount'),
        ...createSagaReducer('getOrderList', 'getOrderList'),
    }
})

export const ORDER = slice.name;
export const orderReducer = slice.reducer;
export const orderAction = slice.actions;
