import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

const ProtectedRoute = ({ children }) => {
  const router = useRouter();
  const { status } = useSession();

  useEffect(() => {
    if(status === "unauthenticated") {
      router.push('/login');
    }
  }, [router, status]);

  if(status === "unauthenticated") return null;


  return <>{children}</>
}

const ExceptAuthURL = ['/login'];
 
const AuthWrapper = ({ children }) => {
  const { status } = useSession();
  const router = useRouter();

  if(status === "loading") return null;

  return (
    <>
      {ExceptAuthURL.includes(router.pathname) ? (
        children
      ) : (
        <ProtectedRoute>{children}</ProtectedRoute>
      )}
    </>
  )
};

export default AuthWrapper;


