import axios from 'axios';
import { HeaderBasic, HeaderEncod, HeaderFormData } from "@store/api.util";

export const getOrderCount = async (payload) => {
    const { ids, ...filteredPayload } = payload;
    if(filteredPayload.status && filteredPayload.status.length === 0) {
        delete filteredPayload.status;
    }
    const params = { 
        ...filteredPayload,
        ...(filteredPayload.status && { status: filteredPayload.status.join(',') })
    }
    
    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders/count`, {
        headers: {
            ...HeaderBasic,
        },
        params: params  
    })
    .then(res => res.data)
    .catch(Error => console.log(Error))
}


export const getOrderList = async (payload) => {

    if(payload.status && payload.status.length === 0) {
        delete payload.status;
    }
    const params = { 
        ...payload,
        ...(payload.status && { status: payload.status.join(',') })
    };
    return await axios.get(`${process.env.NEXT_PUBLIC_CLAYFUL_API_HOST}/orders`, {
        headers: {
            ...HeaderBasic,
        },
        params
    })
    .then(res => res.data)
    .catch(Error => console.log(Error))
};