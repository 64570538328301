import { takeLatest, put, all, fork, select, delay } from "redux-saga/effects"
import * as orderApi from './orderApi';
import createAsyncSaga from "../saga.util";
import { orderAction } from "./orderSlice";

const getOrderListSaga = createAsyncSaga(
    orderApi.getOrderList,
    orderAction.getOrderListSuccess,
    orderAction.getOrderListError,
)

const getOrderCountSaga = createAsyncSaga(
    orderApi.getOrderCount,
    orderAction.getOrderCountSuccess,
    orderAction.getOrderCountError,
)

function* watchOrderListSaga(){
    yield takeLatest(orderAction.getOrderList.type, getOrderListSaga);
}

function* watchOrderCountSaga(){
    yield takeLatest(orderAction.getOrderCount.type, getOrderCountSaga);
}

export function* orderSaga(){
    yield all([
        fork(watchOrderListSaga),
        fork(watchOrderCountSaga),
    ])
}